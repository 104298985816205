import axios from 'axios/index'
import cookie from 'react-cookies'

import apiUrl from './apiUrl'

const updatePricing = (id, body) =>
  axios.put(`${apiUrl}/api/pricing/${id}`, body, {
    headers: { token: cookie.load('artemest') }
  })

const readPricing = (id) =>
  axios.get(`${apiUrl}/api/pricing/${id}`, {
    headers: { token: cookie.load('artemest') }
  })

export { readPricing, updatePricing }
