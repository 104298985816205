import { COUNTRIES_LIST_DROPDOWN } from 'utils/constants'

export const addressFields = [
  'locationName',
  'companyName',
  'address',
  'address2',
  'city',
  'state',
  'zip',
  'country',
  'phone'
]
export const timeFields = [
  {
    id: 'morningTime',
    style: { display: 'flex', flexWrap: 'wrap' },
    rangeInput: true,
    fields: [
      {
        id: 'morningMinTime',
        required: true,
        type: 'time',
        name: 'morningMinTime'
      },
      {
        id: 'morningMaxTime',
        required: true,
        type: 'time',
        name: 'morningMaxTime'
      }
    ]
  },
  {
    id: 'afternoonTime',
    style: { display: 'flex', flexWrap: 'wrap' },
    rangeInput: true,
    fields: [
      {
        id: 'afternoonMinTime',
        name: 'afternoonMinTime',
        required: true,
        type: 'time'
      },
      {
        id: 'afternoonMaxTime',
        name: 'afternoonMaxTime',
        required: true,
        type: 'time'
      }
    ]
  }
]
export const additionalFields = ['shippingLabelRecipent']

export const artemestPickupLocations = [
  {
    locationName: 'Artemest HQ',
    companyName: 'Artemest',
    address: 'Via Savona 97',
    address2: 'Loft D11',
    city: 'Milano',
    state: 'MI',
    zip: '20144',
    country: 'IT',
    phone: '0247712637',
    morningMinTime: '09:00',
    morningMaxTime: '13:00',
    afternoonMinTime: '14:00',
    afternoonMaxTime: '18:00'
  },
  {
    locationName: 'Consea',
    companyName: 'Artemest',
    address: 'Via Buozzi 5',
    address2: 'Capannone 1 c/o Akno business park',
    city: 'Settala',
    state: 'MI',
    zip: '20049',
    country: 'IT',
    phone: '3485321740',
    morningMinTime: '09:00',
    morningMaxTime: '12:00',
    afternoonMinTime: '13:00',
    afternoonMaxTime: '17:00'
  },
  {
    locationName: 'Cefis',
    companyName: 'Artemest',
    address: 'Via dell’Industria 14',
    address2: '',
    city: 'Cormano',
    state: 'MI',
    zip: '20032',
    country: 'IT',
    phone: '023501835',
    morningMinTime: '08:00',
    morningMaxTime: '12:00',
    afternoonMinTime: '13:00',
    afternoonMaxTime: '16:30'
  },
  {
    locationName: 'TimOne',
    companyName: 'Artemest',
    address: 'Via de Amicis 18',
    address2: '',
    city: 'Cornaredo',
    state: 'MI',
    zip: '20007',
    country: 'IT',
    phone: '0293560806',
    morningMinTime: '08:45',
    morningMaxTime: '12:15',
    afternoonMinTime: '13:45',
    afternoonMaxTime: '17:15'
  },
  {
    locationName: 'Marinacci',
    companyName: 'Artemest',
    address: 'Via della tecnica 17',
    address2: '',
    city: 'Deruta',
    state: 'PG',
    zip: '06053',
    country: 'IT',
    phone: '0759710366',
    morningMinTime: '08:00',
    morningMaxTime: '12:30',
    afternoonMinTime: '14:30',
    afternoonMaxTime: '18:00'
  },
  {
    locationName: 'Paolino Packing',
    companyName: 'Artemest',
    address: 'Via Leonardo da Vinci 126',
    address2: '',
    city: 'Sambuca',
    state: 'FI',
    zip: '50028',
    country: 'IT',
    phone: '0558070024',
    morningMinTime: '09:00',
    morningMaxTime: '12:00',
    afternoonMinTime: '14:00',
    afternoonMaxTime: '17:00'
  }
]

const allFields = [...addressFields, ...timeFields, ...additionalFields]

export const createFormFields = (t, fields) => {
  return fields.map((field) => {
    const isCountry = field === 'country'
    const fieldId = field.id ?? field
    const required = fieldId !== 'address2'
    const label = t({ id: fieldId })

    return {
      id: fieldId,
      required: required,
      type: isCountry ? 'select' : 'text',
      options: isCountry ? COUNTRIES_LIST_DROPDOWN : undefined,
      readOnly: fieldId === 'companyName',
      label: `${label} ${required ? '*' : ''}`,
      name: typeof field === 'string' ? field : field.id,
      placeholder: `${label} ${required ? '*' : ''}`,
      ...field
    }
  })
}

export const formInitialValues = () => {
  const values = {}
  allFields.forEach((field) => {
    values[field] = ''
  })
  return values
}

export const validateFields = (values, intl) => {
  const errors = {}
  const pickupTimes = [
    'morningMinTime',
    'morningMaxTime',
    'afternoonMinTime',
    'afternoonMaxTime'
  ]
  const requiredFieldNames = allFields.filter((field) => {
    return (
      field !== 'address2' &&
      field.id !== 'morningTime' &&
      field.id !== 'afternoonTime'
    )
  })

  requiredFieldNames.push(...pickupTimes)

  requiredFieldNames.forEach((rfn) => {
    if (!values[rfn]) {
      errors[rfn] = intl.formatMessage({ id: 'required' })
    }
  })

  pickupTimes.forEach((pickupTime, index) => {
    if (index > 0) {
      const value = values[pickupTime]
      const prevValue = values[pickupTimes[index - 1]]

      if (!value) {
        errors[pickupTime] = intl.formatMessage({ id: 'required' })
      } else if (value < prevValue) {
        errors[pickupTime] = intl.formatMessage({ id: `${pickupTime}Error` })
      }
    }
  })

  if (values['address'].length > 35) {
    errors['address'] = intl.formatMessage(
      {
        id: 'maxLengthError'
      },
      { maxValue: '35' }
    )
  }

  if (values['address2'] && values['address2'].length > 35) {
    errors['address2'] = intl.formatMessage(
      {
        id: 'maxLengthError'
      },
      { maxValue: '35' }
    )
  }

  return errors
}
