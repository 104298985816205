import { Button } from 'components/buttons'
import { get } from 'lodash'
import { func, object, shape } from 'prop-types'
import React, { Component } from 'react'
import { FaHome } from 'react-icons/fa'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import {
  ApproveCollection,
  DisapproveCollection,
  GetCollectionById
} from 'redux/actions/collection-action'
import {
  ApproveAll,
  ReadProdByCollection,
  rejectByIdsAsync,
  Update
} from 'redux/actions/product-action'
import { ApproveVendorById } from 'redux/actions/vendor-action'
import { STATUSES } from 'utils/constants'
import { isProductPending } from 'utils/products'
import { showNotify } from 'utils/toast'

import ColectionCard from '../components/approval-detail-components/colection-card'
import Style from './styles/approval-detail.module.css'

class ApprovalDetail extends Component {
  static defaultProps = {
    authReducer: {},
    collectionReducer: {},
    productReducer: {}
  }
  static propTypes = {
    authReducer: object,
    collectionReducer: object,
    productReducer: object
  }

  componentDidMount() {
    const collectionId = this.getId()

    this.props.GetProductsByCollection({
      collectionId,
      onSuccess: () => {
        this.props.GetCollection({
          id: collectionId,
          ifGetVendor: true
        })
      }
    })
  }

  componentDidUpdate(prevProps) {
    const { productReducer: productReducerPrev } = prevProps
    const products = this.getProducts()
    const productsPrev = get(productReducerPrev, 'GottenProducts', [])
    const apLength = this.approvedProductsLength(products)
    const apLengthPrev = this.approvedProductsLength(productsPrev)
    const ppLength = this.pendingProductsLength(products)
    const ppLengthPrev = this.pendingProductsLength(productsPrev)

    if (!apLengthPrev && apLength && ppLengthPrev && !ppLength) {
      this.collectionApprove()
      this.vendorApprove()
    }

    const rejLength = this.rejectedProductsLength(products)
    const rejLengthPrev = this.rejectedProductsLength(productsPrev)

    if (rejLengthPrev < products.length && rejLength === products.length) {
      this.collectionDisapprove()
    }
  }

  getId = () => get(this.props, 'match.params.collection', '')
  getProducts = () => {
    const { productReducer } = this.props
    return get(productReducer, 'GottenProducts') || []
  }
  getVendorId = () => get(this.props, 'vendorReducer.vendor._id')

  approvedProductsLength = (products = []) =>
    products.filter((product) => get(product, 'status') === STATUSES.APPROVED)
      .length

  collectionApprove = () => {
    const { collectionReducer } = this.props
    const collectionId = this.getId()
    const isCollectionApproved = get(collectionReducer, 'collection.approved')

    if (isCollectionApproved === false) {
      this.props.ApproveCollection(collectionId)
    }
  }

  collectionDisapprove = () => {
    const collectionId = this.getId()

    this.props.DisapproveCollection(collectionId)
  }

  handleApproveAll = () => {
    const collectionId = this.getId()

    this.props.ApproveAll(collectionId)
  }

  handleDisapproveAll = () => {
    const { intl, rejectByIdsAsync } = this.props
    const idsToReject = this.getProducts()
      .filter(
        ({ status, internalUseOnly }) =>
          status !== STATUSES.REJECTED && !internalUseOnly
      )
      .map(({ _id }) => _id)

    rejectByIdsAsync({
      ids: idsToReject,
      onError: () => {
        showNotify({
          isError: true,
          message: intl.formatMessage({
            id: 'Error disapproving products'
          })
        })
      },
      onSuccess: () => {
        showNotify({
          message: intl.formatMessage({ id: 'Products disapproved' })
        })
      }
    })
  }

  isVendorApproved = () => {
    const { vendorReducer } = this.props

    return get(vendorReducer, 'vendor.profile.data.approved')
  }

  pendingProductsLength = (products = []) =>
    products.filter((product) => get(product, 'status') === STATUSES.PENDING)
      .length

  rejectedProductsLength = (products = []) =>
    products.filter((product) => get(product, 'status') === STATUSES.REJECTED)
      .length

  sortProductsByStatus = (arr = []) =>
    arr.reduce((acc, curr) => {
      if (curr.status === 'Pending') {
        acc.unshift(curr)
      } else {
        acc.push(curr)
      }
      return acc
    }, [])

  updateProduct = (id, data) => {
    const { authReducer } = this.props
    const rights = get(authReducer, 'user.rights', [])
    const canApprove = rights.indexOf('approve_product') > -1

    if (canApprove) {
      this.props.Approve({
        productData: data,
        productId: id,
        showPending: true
      })
    }
  }

  vendorApprove = () => {
    const isApproved = this.isVendorApproved()
    const vendorId = this.getVendorId()

    if (isApproved === false && vendorId) {
      this.props.ApproveVendor(vendorId, true)
    }
  }

  renderCards = () => {
    const products = this.getProducts()

    return products.length > 0
      ? this.sortProductsByStatus(products).map((product) => (
          <ColectionCard
            data={product}
            key={product._id}
            noLinkHeading={isProductPending(product)}
            updateProduct={this.updateProduct}
          />
        ))
      : null
  }

  render() {
    const {
      collectionReducer: { collection, fetched }
    } = this.props
    const buttonsAll = [
      {
        label: 'Disapprove all',
        onClick: this.handleDisapproveAll
      },
      {
        label: 'Approve all',
        onClick: this.handleApproveAll
      }
    ]
    const collectionName = get(collection, 'name', '')
    const isGotNoCollection = fetched && !collection

    return isGotNoCollection ? (
      <div className={Style.h2}>
        <FormattedMessage id="collection not found" />
        <div>
          <Link to="/">
            <Button>
              <FormattedMessage id="Back to dashboard" />
            </Button>
          </Link>
        </div>
      </div>
    ) : (
      <div className={Style.main}>
        <Row className={Style.header}>
          <Col sm={6} md={8} lg={8}>
            <h4>{collectionName}</h4>
          </Col>
          <Col className={Style.actions} sm={6} md={4} lg={4}>
            {buttonsAll.map(({ label, onClick }) => (
              <Button key={label} onClick={onClick}>
                <FormattedMessage id={label} />
              </Button>
            ))}
            <div className={Style.home}>
              <Link to="/">
                <FaHome />
              </Link>
            </div>
          </Col>
        </Row>
        <Row className={Style.cardRow}>{this.renderCards()}</Row>
      </div>
    )
  }
}

ApprovalDetail.propTypes = {
  GetProductsByCollection: func,
  GetCollection: func,
  ApproveCollection: func,
  DisapproveCollection: func,
  ApproveAll: func,
  intl: shape({
    formatMessage: func
  }),
  rejectByIdsAsync: func,
  vendorReducer: shape({}),
  Approve: func,
  ApproveVendor: func
}

const mapActions = {
  GetProductsByCollection: ReadProdByCollection,
  Approve: Update,
  ApproveAll,
  ApproveCollection,
  ApproveVendor: ApproveVendorById,
  DisapproveCollection,
  GetCollection: GetCollectionById,
  rejectByIdsAsync
}
const mapStateToProps = (state) => ({
  productReducer: state.productReducer,
  collectionReducer: state.collectionReducer,
  authReducer: state.authReducer,
  vendorReducer: state.vendorReducer
})

export default injectIntl(connect(mapStateToProps, mapActions)(ApprovalDetail))
