import { Status } from 'components'
import { Button } from 'components/buttons'
import { LabelValueList } from 'components/lists'
import { element, func, number, shape, string } from 'prop-types'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { pluralWord } from 'utils/utils'

import st from './OrderDetail.module.css'

const OrderDetail = ({
  buttonPath,
  country,
  intl,
  listPrice,
  netPrice,
  orderNumber,
  productsList,
  productsQuantity,
  shipBy,
  shipmentNumber,
  status
}) => {
  const dataList = [
    {
      label: intl.formatMessage({ id: 'Customer expected shipping date' }),
      value: shipBy
    },
    {
      label: intl.formatMessage({ id: 'country' }),
      value: country
    },
    {
      label: intl.formatMessage({ id: 'Net to vendor' }),
      mod1: true,
      value: netPrice
    },
    {
      label: intl.formatMessage({ id: 'List price' }),
      mod1: true,
      value: listPrice
    }
  ]
  const productLabel = pluralWord('Product', productsQuantity)

  return (
    <article className={st.article}>
      <header className={st.heading}>
        <h1>
          {shipmentNumber} {orderNumber}
        </h1>
        <span className={st.status}>{status}</span>
      </header>
      <Row className={st.cnt}>
        <Col sm={8} xs={12}>
          {productsList ? (
            <>
              <div className={st.productsList}>{productsList}</div>
              {productsQuantity > 0 && (
                <p className={st.productsQuantity}>
                  {productsQuantity} <FormattedMessage id={productLabel} />
                </p>
              )}
            </>
          ) : (
            <Status
              className={st.noProductsStatus}
              color="#14100a"
              fontSize={14}
            >
              <FormattedMessage id="No products for this order shipment" />
            </Status>
          )}
        </Col>
        <Col sm={4} xs={12}>
          <LabelValueList className={st.dataList} items={dataList} />
          <div className={st.buttonWrapper}>
            <Link to={buttonPath}>
              <Button black>
                <FormattedMessage id="More Details" />
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
    </article>
  )
}

OrderDetail.defaultProps = {
  buttonPath: '/',
  country: '',
  listPrice: '',
  netPrice: '',
  orderNumber: '',
  productsList: null,
  productsQuantity: 0,
  shipBy: '',
  shipmentNumber: '',
  status: ''
}
OrderDetail.propTypes = {
  buttonPath: string,
  country: string,
  listPrice: string,
  netPrice: string,
  orderNumber: string,
  productsList: element,
  productsQuantity: number,
  shipBy: string,
  shipmentNumber: string,
  status: string,
  intl: shape({
    formatMessage: func
  })
}

export default injectIntl(OrderDetail)
